
import { defineComponent, ref, watch } from 'vue'
import pagination from '@/components/pagination/pagination.vue'
export default defineComponent({
  name: 'list',
  components: {
    pagination
  },
  props: {
    state: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emit: ['change'],
  setup(props, { emit }) {
    const curData = ref(props.state.data.slice(0, 10))
    console.log('props.state.data11111111111111',props.state.data.value)
    console.log('curData11111111111111',props.state.data.slice(0, 10))
    const curPageNumber = ref(1)

    const handleItemClick = (item: any) => {
      if (props.state.defaultId === item.id) return
      let value = {
        type: props.state.key,
        name: props.state.title + '：' + item.name,
        id: item.id
      }
      emit('change', value)
    }
    watch(
      () => props.state.data,
      () => {
        if (!props.state.data.length) return
        props.state.data.some((item: any, index: number) => {
          if (item.id === props.state.defaultId) {
            curPageNumber.value = Math.ceil(index / 10)
            return true
          }
        })
        if (curPageNumber.value === 0) {
          curData.value = props.state.data.slice(0, 10)
        } else {
          curData.value = props.state.data.slice(
            10 * (curPageNumber.value - 1),
            10 * curPageNumber.value
          )
        }
      }
    )
    const handlePageClick = (data: any[]) => {
      curData.value = data
    }
    return {
      curData,
      curPageNumber,
      handleItemClick,
      handlePageClick
    }
  }
})
