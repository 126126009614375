
import { ref, watch, reactive, defineComponent } from 'vue'
import httpRequest from '@/service'
import { BASE_URL } from '@/service/request/config'
import { IComObj } from '@/global/types'

export default defineComponent({
  props: {
    sortData: {
      type: Array,
      default: () => {
        return []
      }
    },
    sortId: {
      type: String,
      default: '1'
    },
    checkAllFlag: {
      type: Boolean,
      default: false
    },
    checkedAmount: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any, { emit }) {
    const checkedArr = ref(props.checkedAmount)
    const innerAllFlag = ref(props.checkAllFlag)
    const handleCheckAll = (data: boolean) => {
      innerAllFlag.value = data
      emit('checked', data)
    }
    // 升序降序
    const sortState = reactive({
      id: props.sortId,
      current: 1,
      issueFlag: true, // 浏览量
      carryFlag: true, //决定日期
      issueData: [] as IComObj[],
      carryData: [] as IComObj[],
      getData: () => {
        const _this = sortState
        if (!props.sortData.length) return
        props.sortData.forEach((item: any) => {
          if (item.type == '浏览量') {
            _this.issueData = item.list
          } else {
            _this.carryData = item.list
          }
        })
        _this.id = _this.issueData[0].id
      },
      handleClickIssue: () => {
        const _this = sortState
        _this.issueFlag = _this.current == 1 ? !_this.issueFlag : true
        _this.current = 1
        _this.id = _this.issueFlag
          ? _this.issueData[0].id
          : _this.issueData[1].id
        emit('changeSort', _this.id)
      },
      handleClickCarry: () => {
        const _this = sortState
        _this.carryFlag = _this.current == 2 ? !_this.carryFlag : true
        _this.current = 2
        _this.id = _this.carryFlag
          ? _this.carryData[0].id
          : _this.carryData[1].id
        emit('changeSort', _this.id)
      }
    })
    sortState.getData()

    // 批量下载
    const bulkDowmload = () => {
      if (!checkedArr.value.length) return
      httpRequest
        .post({
          url: 'download',
          params: { checkedId: checkedArr.value }
        })
        .then(() => {
          let itUrl = BASE_URL + '/common/download?'
          checkedArr.value.forEach((item: string, index: number) => {
            itUrl += index === 0 ? `checkedId=${item}` : `&checkedId=${item}`
          })
          dowmloadFuc(itUrl)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const dowmloadFuc = (itUrl: any) => {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.style.height = '0'
      a.href = itUrl
      a.download = itUrl
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        a.remove()
      }, 300000)
    }

    watch(
      () => props.checkedAmount,
      () => {
        checkedArr.value = props.checkedAmount
      }
    )

    watch(
      () => props.sortData,
      () => {
        sortState.getData()
      }
    )

    watch(
      () => props.checkAllFlag,
      () => {
        innerAllFlag.value = props.checkAllFlag
      }
    )

    return {
      handleCheckAll,
      sortState,
      bulkDowmload,
      innerAllFlag
    }
  }
})
