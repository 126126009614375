
import { defineComponent, ref, watch, nextTick } from 'vue'
// import httpRequest from '@/service'
// import type Node from 'element-plus/es/components/tree/src/model/node'
interface Tree {
  name: string
  id: string
  value: number
  level: string
  // leaf?: boolean
  children?: Tree[]
}
export default defineComponent({
  name: 'tree',
  props: {
    state: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emit: ['change'],
  setup(props, { emit }) {
    const defaultProps = {
      label: 'name',
      value: 'value',
      children: 'children'
      // isLeaf: 'leaf'
    }
    const defaultKey = ref(props.state.defaultId)
    const popularTree = ref()
    const widthCal = (e: any) => {
      return ` cal(100%-${String(e).length}*8)`
    }
    watch(
      () => props.state.data,
      () => {
        nextTick(() => {
          defaultKey.value = props.state.defaultId
          if (defaultKey.value === '') defaultKey.value = null
          popularTree.value.setCurrentKey(defaultKey.value)
        })
      }
    )

    // const loadNode = (node: Node, resolve: (data: Tree[]) => void) => {
    //    let curId = node.data.id ? node.data.id : ''
    //   httpRequest
    //     .get({
    //       url: props.state.url,
    //       params: { ...props.state.formObj }
    //     })
    //     .then((res: any) => {
    //       return resolve(res.data)
    //     })
    //    .catch((err) => {
    //      console.log(err)
    //    })
    // }
    const handleNodeClick = (data: Tree) => {
      let value = {
        type: props.state.key,
        name: props.state.title + '：' + data.name,
        id: data.id,
        level: data.level
      }
      emit('change', value)
    }

    return {
      popularTree,
      defaultProps,
      defaultKey,
      handleNodeClick,
      widthCal
    }
  }
})
