
import { defineComponent, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import httpRequest from '@/service'
import { deleteAccurate } from '@/utils/common'
import { IComObj } from '@/global/types'
import handleBox from './cpns/handleBox.vue'
import toolBox from './cpns/toolBox.vue'
import { checkToGo } from '@/utils/permission'
interface IState {
  loading: boolean
  flag: boolean
  data: any
  searchData: any
  getData: () => void
}
export default defineComponent({
  name: '列表页',
  components: {
    handleBox,
    toolBox
  },
  setup() {
    const store = useStore()
    console.log(store.state.form, 12)

    watch(
      () => [store.state.formFlag, store.state.form],
      ([newFlag], [oldFlag]) => {
        if (newFlag === 1 && oldFlag === 1) {
          totalState.getData()
          listDataState.getData()
        }
      },
      {
        deep: true
      }
    )
    // 升序降序
    const sortState = reactive({
      id: '1',
      data: [],
      getData() {
        httpRequest
          .post({
            url: 'sortValueList'
          })
          .then((res) => {
            sortState.data = res.data
            listDataState.getData()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleClick: (value: string) => {
        sortState.id = value
        listDataState.getData()
      }
    })
    sortState.getData()
    // 总体数量
    const totalState = reactive({
      total: 0,
      getData() {
        httpRequest
          .post({
            url: 'searchCaseListTotalList',
            params: store.state.form
          })
          .then((res) => {
            totalState.total = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
    totalState.getData()

    // 分页
    const paginationState = reactive({
      currentPage: 1,
      num: 0,
      pageTotal: 0,
      prevText: '上一页',
      nextText: '下一页',
      handleCurrentChange: (val: number) => {
        paginationState.currentPage = val
        listDataState.getData()
      }
    })

    // 列表数据
    const listDataState = reactive<IState>({
      loading: true,
      flag: false,
      data: [],
      searchData: [],
      getData: () => {
        const _this = listDataState
        _this.loading = true
        const params = {
          ...store.state.form,
          sort: sortState.id,
          currentPage: paginationState.currentPage,
          pageSize: 10
        }
        httpRequest
          .post({
            url: 'searchCaseListLawList',
            params
          })
          .then((res) => {
            checkedState.checkedAmount = []
            checkedState.checkAmount = []
            checkedState.allFlag = false

            _this.flag = true
            res.data.forEach((item: any) => {
              item.list.forEach((i: any) => {
                _this.flag = false
                checkedState.checkAmount.push(i.id)
              })
            })
            _this.data = res.data
            _this.searchData = res.customData
            paginationState.pageTotal = res.data[0].pageTotal

            // 每次调取接口都将页面回到顶部
            document.documentElement.scrollTop = 0
            
            _this.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })

    // 全选
    const checkedState = reactive({
      allFlag: false,
      checkAmount: [] as IComObj[],
      checkedAmount: [] as IComObj[],
      handleCheckAll: (val: boolean) => {
        checkedState.allFlag = val
        if (val) {
          checkedState.checkedAmount = JSON.parse(
            JSON.stringify(checkedState.checkAmount)
          )
          listDataState.data.forEach((item: any) => {
            item.list.forEach((i: any) => {
              i.flag = true
            })
          })
        } else {
          checkedState.checkedAmount = []
          listDataState.data.forEach((item: any) => {
            item.list.forEach((i: any) => {
              i.flag = false
            })
          })
        }
      },
      handleCheckSingle: (i: any) => {
        let checkedIndex = checkedState.checkedAmount.indexOf(i.id)
        if (checkedIndex == -1 && i.flag) {
          checkedState.checkedAmount.push(i.id)
          i.flag = true
        } else {
          checkedState.checkedAmount.splice(checkedIndex, 1)
          i.flag = false
        }

        checkedState.allFlag =
          checkedState.checkedAmount.length === checkedState.checkAmount.length
      }
    })

    return {
      listDataState,
      totalState,
      paginationState,
      // effectState,
      sortState,
      checkedState,
      checkToGo
    }
  }
})
