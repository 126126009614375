import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LeftPane" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")!
  const _component_con_box = _resolveComponent("con-box")!
  const _component_tree = _resolveComponent("tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 案件类型 "),
    _createVNode(_component_con_box, {
      title: _ctx.caseState.title,
      slotH: _ctx.caseState.data.length
    }, {
      default: _withCtx(() => [
        _createVNode(_component_list, {
          state: _ctx.caseState,
          onChange: _ctx.caseState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "slotH"]),
    _createCommentVNode(" 行政管理类别 "),
    _createVNode(_component_con_box, {
      title: _ctx.caseState.defaultId === '1' ?'行为类别':'行政管理类别',
      slotH: _ctx.xwlbState.data.length
    }, {
      default: _withCtx(() => [
        _createVNode(_component_list, {
          state: _ctx.xwlbState,
          onChange: _ctx.xwlbState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "slotH"]),
    _createCommentVNode(" 领域名称 "),
    _createVNode(_component_con_box, {
      title: _ctx.lymcState.title,
      slotH: 10
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.lymcState,
          onChange: _ctx.lymcState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createCommentVNode(" 地域名称 "),
    (_ctx.caseState.defaultId==='1')
      ? (_openBlock(), _createBlock(_component_con_box, {
          key: 0,
          title: _ctx.caseAreas.title,
          slotH: _ctx.caseAreas.data.length
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tree, {
              state: _ctx.caseAreas,
              onChange: _ctx.caseAreas.handleItemClick
            }, null, 8 /* PROPS */, ["state", "onChange"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title", "slotH"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" 复议事项 "),
    (_ctx.caseState.defaultId==='3')
      ? (_openBlock(), _createBlock(_component_con_box, {
          key: 1,
          title: _ctx.fysxState.title,
          slotH: _ctx.fysxState.data.length
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tree, {
              state: _ctx.fysxState,
              onChange: _ctx.fysxState.handleItemClick
            }, null, 8 /* PROPS */, ["state", "onChange"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title", "slotH"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" 结案类型 "),
    (_ctx.caseState.defaultId==='3')
      ? (_openBlock(), _createBlock(_component_con_box, {
          key: 2,
          title: _ctx.jalxState.title,
          slotH: _ctx.jalxState.data.length
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tree, {
              state: _ctx.jalxState,
              onChange: _ctx.jalxState.handleItemClick
            }, null, 8 /* PROPS */, ["state", "onChange"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title", "slotH"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" 决定年份 "),
    _createCommentVNode("    <con-box :title=\"jdnfState.title\" :slotH=\"jdnfState.data.length\">"),
    _createCommentVNode("      <template v-slot>"),
    _createCommentVNode("        <list :state=\"jdnfState\" @change=\"jdnfState.handleItemClick\"></list>"),
    _createCommentVNode("      </template>"),
    _createCommentVNode("    </con-box>")
  ]))
}