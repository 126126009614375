
import { defineComponent, reactive, watch, ref } from 'vue'
import httpRequest from '@/service'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import conBox from './cpns/conBox.vue'
import list from './cpns/list.vue'
import tree from './cpns/tree.vue'
interface IObj {
  id: string
  [key: string]: any
}
interface ILeftPaneState {
  title: string
  data: IObj[]
  defaultId: string
  level?: string
  url?: string
  loading?: boolean
  getData: () => void
  handleItemClick: (obj: any) => void
}
export default defineComponent({
  name: 'LeftPane',
  components: {
    conBox,
    list,
    tree
  },
  setup() {
    const store = useStore()
    const route = useRoute();
    watch(
      () => [store.state.formFlag, store.state.form],
      ([newFlag, newForm], [oldFlag]) => {
        console.log('caselist', newForm.value, newFlag, oldFlag)
        if (newFlag === 1 && oldFlag === 1) {
          [
            lymcState.defaultId,
            lymcState.level,
            xwlbState.defaultId,
            jdnfState.defaultId,
            caseState.defaultId,
            caseAreas.defaultId,
            fysxState.defaultId,
            jalxState.defaultId
          ] = [
            store.state.form.fieldId,
            store.state.form.fieldLevel,
            store.state.form.behaviorId,
            store.state.form.decideYear,
            store.state.form.caseFormId,
            store.state.form.caseArea,
            store.state.form.reconsiderMatter,
            store.state.form.closeType
          ]
          lymcState.getData()
          xwlbState.getData()
          jdnfState.getData()
          caseState.getData()
          caseAreas.getData()
          fysxState.getData()
          jalxState.getData()
        }
      },
      {
        deep: true
      }
    )

    // 点击每一项，需要判断检索栏中是否已有该类别
    const searchListAction = (obj: any) => {
      let arr = store.state.searchList
      let typeFlag = arr.some((item: any) => {
        if (item.type === obj.type) {
          return true
        }
      })
      let nameFlag = arr.some((item: any) => {
        if (item.name === obj.name) {
          return true
        }
      })
      if (typeFlag && !nameFlag) {
        arr.splice(
          arr.findIndex((i: any) => obj.type === i.type),
          1
        )
        arr.push(obj)
        store.commit('setSearchList', arr)
      } else if (typeFlag && nameFlag) {
        return
      } else if (!typeFlag) {
        arr.push(obj)
        store.commit('setSearchList', arr)
      }
    }
    // 顺序：领域名称、行为类别、案例类型、决定年份

    // 领域名称
    const lymcState: any = reactive({
      title: '领域名称',
      key: 'fieldId',
      data: [],
      defaultId: store.state.form.fieldId,
      level: store.state.form.fieldLevel,
      loading: true,
      getData() {
        lymcState.loading = true
        httpRequest
          .post({
            url: 'fieldList',
            params: store.state.form
          })
          .then((res: any) => {
            lymcState.data = res.data
            lymcState.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        const filterObj = store.state.form
        filterObj.fieldId = lymcState.defaultId = obj.id
        filterObj.fieldLevel = lymcState.level = obj.level
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    lymcState.getData()

    
    const caseState: ILeftPaneState = reactive({
      title:'案件类型',
      key: 'defaultId', // 根据返回数据确定
      data: [],
      defaultId: store.state.form.behaviorId,
      getData() { //接口待定
        httpRequest
          .post({ url: 'getCaseType', params: store.state.form })
          .then((res: any) => {
            caseState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        caseState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.caseFormId = obj.id
        // filterObj.behaviorId = obj.id 参数待定
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    caseState.getData()
    // 案例类型，根据路由传递的数值初始化
    const type = route.params.type
    caseState.defaultId = (type==='1')?'1':'3'

    // 行政管理类别 //行为类别
    const xwlbState: ILeftPaneState = reactive({
      title: store.state.form.behaviorId === '1' ?'行为类别':'行政管理类别',
      key: 'behaviorId',
      data: [],
      // loading: true,
      defaultId: store.state.form.behaviorId,
      getData() {
        // xwlbState.loading = true
        httpRequest
          .post({ url: 'behaviorList', params: store.state.form })
          .then((res: any) => {
            xwlbState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        xwlbState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.behaviorId = obj.id
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    xwlbState.getData()

    // 决定年份
    const jdnfState: ILeftPaneState = reactive({
      title: '决定年份',
      key: 'decideYear',
      data: [],
      defaultId: store.state.form.decideYear,
      getData() {
        httpRequest
          .post({ url: 'decideYear', params: store.state.form })
          .then((res: any) => {
            jdnfState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        jdnfState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.decideYear = obj.id
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    jdnfState.getData()

    
    // 案件地域
    const caseAreas: ILeftPaneState = reactive({
      title:'案件地域',
      key: 'defaultId', // 根据返回数据确定
      data: [],
      defaultId: store.state.form.caseArea,
      getData() { //接口待定
        httpRequest
          .post({ url: 'areasList', params: store.state.form })
          .then((res: any) => {
            caseAreas.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        caseAreas.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.caseArea = obj.id
        // filterObj.behaviorId = obj.id 参数待定
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    caseAreas.getData()
    // 复议事项
    const fysxState: ILeftPaneState = reactive({
      title:'复议事项',
      key: 'defaultId', // 根据返回数据确定
      data: [],
      defaultId: store.state.form.reconsiderMatter,
      getData() { //接口待定
        httpRequest
          .post({ url: 'matterList', params: store.state.form })
          .then((res: any) => {
            fysxState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        fysxState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.reconsiderMatter = obj.id
        // filterObj.behaviorId = obj.id 参数待定
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    fysxState.getData()
    //结案类型
    const jalxState: ILeftPaneState = reactive({
      title:'结案类型',
      key: 'defaultId', // 根据返回数据确定
      data: [],
      defaultId: store.state.form.closeType,
      getData() { //接口待定
        httpRequest
          .post({ url: 'closeTypeList', params: store.state.form })
          .then((res: any) => {
            jalxState.data = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        jalxState.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.closeType = obj.id
        // filterObj.behaviorId = obj.id 参数待定
        store.commit('setForm', filterObj)
        // 此处提交文字到vuex数组中，用于检索条件展示
        searchListAction(obj)
      }
    })
    jalxState.getData()
    return {
      lymcState,
      xwlbState,
      jdnfState,
      caseState,
      caseAreas,
      fysxState,
      jalxState
    }
  }
})
